@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./colors');
@import url('./fonts');
@import url('./spacing');
@import url('./loader');
@import url('./slider');
@import url('https://fonts.googleapis.com/css2?family=Manrope');

html,
body {
  font-family: 'Manrope', sans-serif;
  margin: 0px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
  z-index: 1;
}

button {
  background: none;
}

.slick-slide {
  margin: 0 0.5rem;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #fe5000 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  /* border-radius: 5px; */
  background-color: #fe5000;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ff8e5a;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ff8e5a;
}

.fixed-to-bottom {
  position: fixed;
  /* padding: 10px 10px 0px 10px; */
  bottom: 0;
  height: 60px;
}
.wrapper {
  height: calc(100dvh - 100px - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Data Table Styles Start */

.rdt_TableHeadRow {
 font-size: 14px;
 text-align: center;
}

#orderDatatableWrapper [data-column-id="6"].rdt_TableCol {
  max-width: 80px;
}

#orderDatatableWrapper .rdt_TableRow {
  cursor: pointer;
}
.rdt_TableRow {
  padding: 10px;
}

#cell-9-undefined, #cell-10-undefined {
  padding: 8px 0px;
  justify-content: center;
  border-radius: 16px;
  color: white;
  text-transform: capitalize;
}
#orderOverviewDatatableWrapper #cell-4-undefined {
  color: #FE5000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}
#orderDatatableWrapper #cell-5-undefined {
  color: #FE5000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

#orderDatatableWrapper #cell-6-undefined {
  padding: 8px 0px;
  justify-content: center;
  border-radius: 16px;
  color: white;
  text-transform: capitalize;
}
/* Data Table Styles Ends */

.category-image-shadow {
  box-shadow: 0px 1px 19px 0px rgba(0, 13, 56, 0.06);

}

.z-20 {
  z-index: 20 !important;
}
.slick-track {
  display: flex !important;
}

.bg-grey {
  background-color: #F9FAFB;
}

#nav-header .incomplete-marquee {
  background-color: #fe5000;
  color: white;
  font-weight: 700;
}
#nav-header .pending-marquee {
  background-color: yellow;
  
}

/* Reset styles for input fields and dropdowns */
/* Remove styles on hover */
input:hover,
select:hover,
textarea:hover {
  background: none;
  color: inherit;
}

/* Remove styles on focus */
input:focus,
select:focus,
textarea:focus {
  box-shadow: none;
  outline: none;
}

/* Remove styles on active */
input:active,
select:active,
textarea:active {
  background: none;
  color: inherit;
  box-shadow: none;
  outline: none;
}

/* Remove styles on disabled */
/* input:disabled,
select:disabled,
textarea:disabled {
  background: none;
  color: inherit;
  opacity: 1;
  cursor: not-allowed;
} */

/* Remove styles on autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-visible,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:focus-visible,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus, 
select:-webkit-autofill:focus-visible {
  background-color: transparent !important;
  color: inherit !important;
  font-size: inherit !important;
  box-shadow: none !important;
  border: none;
}

.ql-video {
  width: 590px;
  height: 500px;
  max-width: 100%;
}