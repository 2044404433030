.product-images-slider {
    height: auto;
    width: 100%;
}

.product-images-slider .swiper-slide {
    position: relative; 
    overflow: hidden;
    height: auto;
}

.product-images-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.product-images-slider .swiper-button-prev,
.product-images-slider .swiper-button-next {
    color: #000;
    z-index: 10;
}

.product-images-slider-thumbs .swiper-slide {
    cursor: pointer;
    border: 1px solid #dddbdb;
}

.product-images-slider-thumbs .swiper-slide-thumb-active {
    border-color: #f00;
}

.product-images-slider-thumbs-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.product-images-slider-thumbs-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.zoom-container {
    overflow: hidden;
    position: relative;
  }
  
  .zoom-image {
    transition: transform 0.5s ease-in-out;
  }
  
  @media (min-width: 768px) {
  .zoom-container:hover .zoom-image {
    transform: scale(1.2);
  }
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    font-size: 24px !important;
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 36px !important;
}